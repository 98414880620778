var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(
      _vm.$route.name != 'Login' &&
      _vm.$route.name != 'RecoverPass' &&
      _vm.$route.name != 'Calendario'
    )?_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('a',{attrs:{"href":"http://www.lahabitacionsaludable.com/","target":"_blank"}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"La habitación saludable Logo","contain":"","src":require("@/assets/logo.png"),"transition":"scale-transition","width":"150"}})],1)]),_c('v-spacer'),(_vm.shouldShowSelect)?_c('v-select',{staticClass:"centerSelect",attrs:{"label":"Centro activo:","items":this.$store.state.centers,"item-text":"nombre","item-value":"id"},on:{"change":function($event){return _vm.changeCenter()}},model:{value:(_vm.idCenter),callback:function ($$v) {_vm.idCenter=$$v},expression:"idCenter"}}):_vm._e()],1):_vm._e(),_c('v-main',[(
        _vm.$route.name != 'Login' &&
        _vm.$route.name != 'RecoverPass' &&
        _vm.$route.name != 'Newsletter' &&
        _vm.$route.name != 'Embarazada' &&
        _vm.$route.name != 'ClubLHS' &&
        _vm.$route.name != 'Valora' &&
        _vm.$route.name != 'Calendario'
      )?_c('NavBar'):_vm._e(),_c('v-container',{key:this.$store.state.reload,staticClass:"cMain",attrs:{"fluid":""}},[(this.$store.state.keepAlive)?_c('keep-alive',{attrs:{"include":"Clientes,Talleres"}},[_c('router-view')],1):_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }