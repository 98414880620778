<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      v-if="
        $route.name != 'Login' &&
        $route.name != 'RecoverPass' &&
        $route.name != 'Calendario'
      "
    >
      <div class="d-flex align-center">
        <a href="http://www.lahabitacionsaludable.com/" target="_blank">
          <v-img
            alt="La habitación saludable Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="150"
          />
        </a>
      </div>

      <v-spacer></v-spacer>

      <v-select
        v-if="shouldShowSelect"
        v-model="idCenter"
        class="centerSelect"
        label="Centro activo:"
        :items="this.$store.state.centers"
        item-text="nombre"
        item-value="id"
        @change="changeCenter()"
      ></v-select>
    </v-app-bar>
    <v-main>
      <NavBar
        v-if="
          $route.name != 'Login' &&
          $route.name != 'RecoverPass' &&
          $route.name != 'Newsletter' &&
          $route.name != 'Embarazada' &&
          $route.name != 'ClubLHS' &&
          $route.name != 'Valora' &&
          $route.name != 'Calendario'
        "
      />
      <v-container class="cMain" fluid :key="this.$store.state.reload">
        <!-- key="this.$store.state.idCenter" -->
        <keep-alive
          v-if="this.$store.state.keepAlive"
          include="Clientes,Talleres"
        >
          <router-view />
        </keep-alive>
        <router-view v-else />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { BASE_URL } from "@/components/constants.js";
export default {
  name: "App",
  data() {
    return {
      idCenter: this.$store.state.idCenter,
    };
  },
  computed: {
    shouldShowSelect() {
      const url = window.location.href;
      return !/ClubLHS|Embarazada|Valora/.test(url);
    },
  },
  components: {
    NavBar,
  },
  async mounted() {
    if (this.$store.state.auth && this.$store.state.auth != "") {
      await this.checkJWT();
    } else {
      this.$store.commit("modifyAuth", "");
    }
  },
  methods: {
    async checkJWT() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.auth,
      };
      const response = await fetch(`${BASE_URL}/api/auth/checkJWT`, {
        method: "POST",
        headers: headers,
      });
      if (response.status == 200) {
        await this.$store.dispatch("loadData");
        this.idCenter = this.$store.state.idCenter;
      } else {
        this.$store.commit("modifyAuth", "");
        this.$router.push({ name: "Login" });
      }
    },
    async changeCenter() {
      this.$store.commit("modifyActualCenter", this.idCenter);
      this.$store.commit("modifyReload", Math.random());
      // setTimeout(function(){ location.reload(), 1000});
    },
  },
};
</script>
<style>
.cMain {
  padding: 0px !important;
}
.botonLHS {
  border: 4px solid #000 !important;
  padding: 23px 29px !important;
  background-color: transparent !important;
  color: #000 !important;
  margin-right: 2%;
  margin-top: 2%;
}
.botonLHS:hover {
  border: 4px solid #06efe8 !important;
  background: #06efe8 !important;
  color: #fff !important;
}
.botonLHSmini {
  border: transparent !important;
  background-color: transparent !important;
  color: #000 !important;
  width: 100% !important;
  height: 60% !important;
}
.botonLHSmini:hover {
  color: #06efe8 !important;
}
.botonLHSmini2 {
  border: transparent !important;
  background-color: transparent !important;
  color: #000 !important;
  margin-right: 2%;
  margin-top: 2%;
}
.botonLHSmini2:hover {
  color: #06efe8 !important;
}
.insideCard {
  padding: 20px;
}
.centerSelect {
  height: 50%;
  max-width: 20% !important;
}
.v-input--is-disabled,
.v-input--is-disabled input,
.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-label--is-disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
